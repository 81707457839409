@import url('https://rsms.me/inter/inter.css');

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Inter', sans-serif;
}

#root,
.App,
body {
  height: 100%;
}
