.modal-add-customer {
  .test {
    .ant-form-item-explain {
      padding-left: 1.2%;
    }
    width: 100% !important;
    .form-items {
      width: 100% !important;
      .form-items-money {
        width: 95% !important;
        margin-left: 10px;
      }
    }
  }

  .ant-form-item-label {
    text-align: left;
    font-weight: normal;
    label {
      color: rgba(0, 0, 0, 0.65);
    }
  }
  .button-add-customer-modal-container {
    display: flex;
    justify-content: center;
    .btn-modal-add {
      margin: 0 10px;
      padding: 0 30px !important;
    }
  }
}
