.add-doctor {
  box-sizing: border-box;
  background-color: #fff;
  padding: 20px;
  h1 {
    font-weight: 500;
    font-size: 20px;
  }
  .box-image {
    margin: 0 0 10px 0 !important;
    .box-image_1 {
      .ant-upload-select-picture-card {
        width: 150px !important;
        height: 150px !important;
        z-index: 1000 !important;
      }
      .avatar {
        .ant-form-item-explain {
          padding-top: 30px !important;
        }
      }
    }
    .box-image_2 {
      .ant-upload-select-picture-card {
        width: 150px !important;
        height: 150px !important;
        z-index: 1000 !important;
      }
      .cover {
        .ant-form-item-explain {
          padding-top: 30px !important;
        }
      }
    }
  }

  .form-item {
    .form-nested {
      &:first-child {
        margin-bottom: 0;
      }
    }
    .addPlace {
      display: flex;
      align-items: center;
    }
    .wrap-btn-add {
      height: 0;
      margin: 0;
      padding: 0;
    }
  }
  .input-type {
    width: 100%;
  }
  .fee {
    position: relative;
    .ant-form-item-explain {
      padding-left: 5%;
    }

    .title-money {
      position: absolute;
      display: flex;
      left: 37%;
      padding-top: 5px;
    }
  }

  .button-container {
    padding-top: 20px;
    display: flex;
    justify-content: center;
    button {
      margin: 0 0.5rem;
    }
  }
  .specialist.add-doctor .ant-form-item-control-input {
    width: 25% !important;
    margin-left: -20px;
  }
}
.ant-checkbox-group {
  max-height: 250px;
  overflow-y: auto;
  .ant-checkbox-wrapper {
    margin: 5px !important;
    display: block;
  }
}
.specialist {
  .ant-form-item-label {
    display: flex;
  }
  .ant-form-item-control-input {
    width: 25%;
  }
}
.specialist-checkbox {
  display: flex !important;
}
.ant-col-18 {
  width: 92%;
}
.add-doctor {
  .specialist .ant-form-item-control-input {
    width: 25% !important;
  }
}