.diagnostic-Contaier {
  .diagnostic-content {
    .diagnostic-title {
      width: 100%;
      font-size: 20px;
      height: 40px;
      font-weight: bold;
    }
  }
  .row-1 {
    background-color: #f2f2f2;
    margin: 30px 20px;
    .diagnostic-section {
      display: flex;
      flex-direction: column;
      word-break: break-all;
      .diagnostic-section__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 10px;
        font-weight: bold;
        .btn-edit {
          padding-top: 10px;
          margin-right: 10px;
          button {
            border-radius: 5px;
          }
        }
      }
      .diagnostic-section__content {
        padding: 20px 10px;
        .diagnostic-section__content_inside {
          word-wrap: break-word;
        }
      }
      .no-content {
        text-align: center;
        padding: 10px;
      }
    }
  }
  .diagnostic-add-container {
    margin: 30px 20px;

    .diagnostic-action-add {
      display: flex;
      justify-content: space-between;
      .btn-ok {
        button {
          padding: 0 45px;
          border-radius: 5px;
        }
      }
      .btn-cancel {
        padding-top: 5px;
        cursor: pointer;
      }
    }
  }
}

.radioButtonWrapper {
  margin-top: 20px;
  margin-bottom: 20px;
}
