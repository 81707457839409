.requested-Contaier {
  .requested-content {
    .requested-title {
      width: 100%;
      font-size: 20px;
      height: 40px;
      font-weight: bold;
    }
  }
  .row-1 {
    background-color: #f2f2f2;
    margin: 30px 20px;
    display: flex;
    flex-direction: column;
    .anamnesis-section__title {
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 10px;
      font-weight: bold;
      .btn-edit {
        padding-top: 10px;
        margin-right: 10px;
        button {
          border-radius: 5px;
        }
      }
    }
    .no-content {
      padding-top: 10px;
      // border: 1px solid rgba(0,0,0,0.5);
      width: 100%;
      text-align: center;
    }
    .requested-section {
      display: flex;
      justify-content: space-between;
      .requested-section__title {
        height: 35px;
        align-items: center;
        padding: 10px;
        font-weight: bold;
      }
      .btn-edit {
        padding: 5px 10px 0 0;
        button {
          border-radius: 5px;
        }
      }
    }
    .requested-section__content {
      padding: 0px 10px 0px 10px;
      .descriptionContainer {
        padding: 10px 0px;
      }
    }
  }
  .requested-add-container {
    margin: 30px 20px;
    .requested-action-add {
      display: flex;
      justify-content: space-between;
      .btn-ok {
        button {
          padding: 0 45px;
          border-radius: 5px;
        }
      }
      .btn-cancel {
        padding-top: 5px;
        cursor: pointer;
      }
    }
  }
}
.drugOrder-section__subTitle {
  margin-top: -10px;
}
.ant-upload-picture-card-wrapper {
  display: contents !important;
  width: 0% !important;
}
.ant-image {
  margin-bottom: 5px !important;
}
.custom-upload {
  .ant-upload.ant-upload-select-picture-card {
    height: 138px;
    width: 100px;
  }
  .ant-upload-select-picture-card {
    background: none !important;
  }
  .ant-upload-list-picture-card .ant-upload-list-item {
    height: 138px !important;
    width: 100px !important;
  }

  .ant-upload-list-picture .ant-upload-list-item,
  .ant-upload-list-picture-card .ant-upload-list-item {
    padding: 0;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
  .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
    object-fit: none;
  }
  .ant-upload-list-item-actions .anticon-delete {
    display: flex !important;
  }
  .ant-upload-list-item-actions .anticon-delete svg {
    fill: currentColor !important;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-actions {
    bottom: 85% !important;
    left: 100% !important;
    top: 0% !important;
  }
  .ant-btn-icon-only.ant-btn-sm > * {
    font-size: 20px;
  }
  .ant-upload-list-picture-card-container {
    display: inline-table !important;
    width: 107px;
  }
  .ant-upload-list {
    display: contents !important;
  }
}
