.version-management {
  h1 {
    font-weight: 500;
    font-size: 20px;
  }
}
.ant-tooltip-inner {
  width: max-content;
}
.input-search {
  :placeholder-shown {
    text-overflow: ellipsis;
  }
}
