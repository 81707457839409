.question-Contaier {
  .question-content {
    .question-content__title {
      width: 100%;
      font-size: 20px;
      height: 40px;
      font-weight: bold;
    }
    .row-1 {
      background-color: #f2f2f2;
      margin: 10px 20px 15px 20px;
      .question-section {
        display: flex;
        flex-direction: column;
        .question-section__title {
          height: 35px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-left: 10px;
          font-weight: bold;
          .btn-edit {
            padding-top: 10px;
            margin-right: 10px;
            margin-bottom: 2px;
            button {
              border-radius: 5px;
            }
          }
        }
        .no-content {
          text-align: center;
          padding: 10px;
        }
        .question-section__content {
          padding: 10px 10px;
          .question-section__content_inside {
            word-wrap: break-word;
          }
        }
      }
    }
  }
  .question-add-container {
    margin: 30px 20px;
    .question-action-add {
      display: flex;
      justify-content: space-between;
      .btn-ok {
        button {
          padding: 0 45px;
          border-radius: 5px;
          margin-top: 10px;
        }
      }
      .btn-cancel {
        padding-top: 15px;
        cursor: pointer;
      }
    }
  }
}
