.suggestion {
  .btn-suggestion {
    border-radius: 10px !important;
    margin: 5px !important;
    box-sizing: border-box !important;
    background-color: rgba(0, 0, 0, 0.1) !important;
  }
}

.button-cancel-appointment-container {
  display: flex;
  justify-content: center;
}
