$tablet-width: 768px;
$desktop-width: 1500px;
$smallerTablet-width: 1500px;
@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin smallerTablet {
  @media (max-width: #{$smallerTablet-width}) {
    @content;
  }
}

.detail-appointment-container {
  font-family: 'ArialMT', 'Arial', sans-serif;
  font-weight: 400;
  font-style: normal;
  flex: 1;
  margin: -24px -24px 0;
  padding: 24px 24px 0;
  .form-item-appointment-detail {
    margin: 0 !important;
    padding: 0 !important;
  }
  .btn-update {
    border-radius: 5px;
  }

  .detail-container__nav {
    z-index: 11;
    background-color: #fff;
    top: -24px;
    width: 100%;
    position: sticky;
    position: -webkit-sticky;

    .row-1 {
      height: 60px;
      box-sizing: border-box;
      .text-back {
        font-size: 14px;
      }
      .col-1__container {
        display: flex;
        margin-top: 15px;
        cursor: pointer;
        .col-1__container_icon {
          display: flex;
          align-items: center;
          svg {
            font-size: 20px;
          }
        }
        .col-1__container_span {
          display: flex;
          align-items: center;
          a {
            text-decoration: none;
          }
        }
      }
      .col-2 {
        text-align: center;
        padding-top: 15px;
        .title-appointment__code {
          font-family: 'Arial-BoldMT', 'Arial Bold', 'Arial', sans-serif;
          font-weight: 700;
          font-style: normal;
          font-size: 18px;
        }
      }
      .col-3 {
        .col-3__group-button {
          justify-content: flex-end;
          display: flex;
          padding-top: 15px;
          align-items: center;

          .btn-1 {
            border-radius: 5px;
          }
          .btn-2 {
            border-radius: 5px;
            span {
              color: #fff !important;
            }
          }
        }
      }
    }
    #tabs {
      height: 100% !important;
      margin: 0px auto !important;
      min-height: 40px !important;

      .ul-tab {
        margin: 0px auto;
        max-width: 100%;
        overflow: auto;
        overflow-y: hidden;
        li {
          box-sizing: border-box;
          text-align: center;
          min-width: 100px;
          padding-top: 10px;
          // margin-right: 25px;
          // margin-left: 25px;
          &:nth-last-child(4) {
            @include smallerTablet {
              min-width: 140px !important;
            }
          }
        }
        .header-label {
          box-sizing: border-box;
          text-decoration: none;
          color: black;
          white-space: nowrap;
        }

        .label-active {
          // @include tablet {
          //   box-sizing: border-box;
          //   width: 100px;
          // }
          text-align: center;
          border-bottom: 4px solid #169bd5;
        }
        // .active-tab {
        //   width: 12px;
        //   height: 2px;
        //   background-color: red;
        // }
      }
    }
  }

  #containerElement {
    // -ms-overflow-style: none;
    // &::-webkit-scrollbar {
    //   display: none;
    // }
    .detail-container__content {
      height: 100%;
      margin: 0 auto;
      margin-top: 20px;
      .detail-container__content_inside {
        width: 85%;
        height: 100%;
        margin: 0 auto;

        .detail-container__content_insideBox {
          padding: 10px 0;
          background-color: rgba($color: #fff, $alpha: 1);
          margin: 20px 0;
          box-sizing: border-box;
        }
      }
    }
  }
}

.button-call {
  justify-content: center;
  display: flex;
  align-items: center;
  outline: none;
}
