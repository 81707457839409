.doctor-information {
  box-sizing: border-box;
  h1 {
    font-weight: 500;
    font-size: 20px;
  }
  .add-doctor-action-table {
    display: flex;
    .ml-1 {
      &hover {
        transform: scale(2);
      }
    }
  }
}
.ant-table-thead {
  tr {
    th {text-align: center;}
  }
}
.ant-table-tbody{
  tr {
    td {text-align: center;}
  }
}

.search-tool {display: flex; justify-content: left; align-items: center;
  .ant-form-item {margin-right:10px}
  .search-input {
    .ant-form-item-control {min-width: 500px;}
  }
  .package-type {
    .ant-form-item-control {min-width: 50px;}
  }
}