.fee {
  position: relative;
  .ant-form-item-explain {
    padding-left: 12%;
  }
  .fee-test {
    width: 80%;
    box-sizing: border-box;
    .input-type {
      width: 75%;
      margin-left: 12%;
    }
  }
  .title-money {
    position: absolute;
    display: flex;
    right: 20%;
    padding-top: 5px;
  }
}
