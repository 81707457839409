.titleContainer {
  width: 100%;
  font-size: 20px;
  height: 40px;
  font-weight: bold;
}

.contentContainer {
  background-color: #f2f2f2;
  margin: 0px 20px;
  padding: 20px 10px;
}
