.detail-doctor {
  box-sizing: border-box;
  background-color: #fff;
  padding: 20px;
  .input-default {
    max-width: 50%;
    width: 100%;
  }
  h1 {
    font-weight: 500;
    font-size: 20px;
  }
  .image-container {
    margin: 0 0 70px 0;
  }
  .avatar {
    margin: 0 !important;
    padding: 0 !important;

    box-sizing: border-box !important;
    .blank_image {
      border: 1px solid rgba($color: #000000, $alpha: 0.1);
      width: 150px;
      height: 150px;
      background: url('../../../assets/images/common/testuse.png') !important;
      background-size: cover !important;
      background-position-x: -18px !important;
    }
  }
  .cover {
    margin: 0 !important;
    padding: 0 !important;

    box-sizing: border-box !important;
    .blank_image {
      border: 1px solid rgba($color: #000000, $alpha: 0.1);
      width: 150px;
      height: 150px;
      background: url('../../../assets/images/common/testuse.png') !important;
      background-size: cover !important;
      background-position-x: -18px !important;
    }
  }
  .form-item {
    padding: 0;
    .addPlace {
      display: flex;
      align-items: center;
    }
    .wrap-btn-add {
      height: 0;
      margin: 0;
      padding: 0;
    }
  }
  .exp {
    position: relative;

    .test {
      width: 100%;
      box-sizing: border-box;
      .input-default {
        width: 50%;
      }
    }

    .title-year {
      position: absolute;
      display: flex;
      left: 63.2%;
      padding-top: 5px;
    }
  }
  .label-money {
    padding: 0 1.5rem 0 0.5rem;
  }

  .fee {
    position: relative;
    .fee-test {
      width: 100%;
      box-sizing: border-box;
      .input-default {
        width: 50%;
      }
    }
    .title-money {
      padding-top: 6px;
      position: absolute;
      left: 63.2%;
    }
    .label-view-detail {
      padding-top: 6px;
      position: absolute;
      left: 68%;
    }
  }
  .fee-detail {
    position: relative;
    .test {
      width: 80%;
      box-sizing: border-box;
      .input-fee-detail {
        width: 25%;
        float: left;
        margin-left: 5%;
      }
    }
    // .label-money {
    //   position: absolute;
    //   display: flex;
    //   left: 35%;
    //   padding-top: 5px;
    // }
    // .label-view-detail {
    //   position: absolute;
    //   display: flex;
    //   left: 38%;
    //   padding-top: 5px;
    // }
  }
  .button-container {
    padding-top: 20px;
  }
}

.checkbox-cp {
  .ant-checkbox-wrapper {
    margin-left: 0px !important;
  }
}
// .row-bonus{
//   display: flex;
// }
.wrapListCheckbox {
  .ant-row.ant-form-item.form-item {
    width: 100% !important;
  }
}