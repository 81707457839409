.version-management {
  h1 {
    font-weight: 500;
    font-size: 20px;
  }
}
.input-number-custom {
  width: 100%;
  @media only screen and (max-width: 961px) {
    width: 40%;
  }
}
