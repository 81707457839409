.followUp-Contaier {
  padding-top: 30px;
  .followUp-content {
    .followUp-title {
      width: 100%;
      font-size: 20px;
      height: 40px;
      font-weight: bold;
    }

    .row-1 {
      // background-color: #f2f2f2;
      border: 1px solid #f2f2f2;
      margin: 30px 20px;
      padding: 0px 0px 10px 0px;

      .anamnesis-section__title {
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 10px;
        font-weight: bold;
        .btn-edit {
          padding-top: 10px;
          margin-right: 10px;
          button {
            border-radius: 5px;
          }
        }
      }
      .no-content {
        padding: 10px 0;
        // border: 1px solid rgba(0,0,0,0.5);
        width: 100%;
        text-align: center;
      }
      .followUp-section {
        display: flex;
        flex-direction: column;
        .followUp-section__title {
          height: 35px;
          display: flex;
          align-items: center;
          padding-left: 10px;
          font-weight: bold;
          justify-content: space-between;
          .btn-edit {
            margin-top: 10px;
            margin-right: 10px;
            border-radius: 5px;
          }
        }
        .followUp-section__timeEdit {
          padding-left: 10px;
        }

        .followUp-section__content {
          padding: 0px 10px;
          .followUp-section__content_inside {
            word-break: break-all;
            margin: 10px 0px 10px;
            background-color: #f2f2f2;
            padding: 5px 10px;
          }
        }
      }
    }
  }
  .followUp-btn-add-info {
    margin: 30px 20px;

    button {
      border-radius: 5px;
    }
  }
}

.drugOrder-section__subTitle {
  height: 35px;
  display: flex;
  align-items: center;
  font-weight: bold;
}

.followUp-section__subTitle {
  display: flex;
  align-items: center;
  font-weight: bold;
}

.follow-result-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.followUp-btn-add-result {
  margin: 15px 0px 10px 0px;
  button {
    border-radius: 5px;
  }
}

.noContentContainer {
  background-color: #f2f2f2;
  margin: 30px 20px;
}
