.ant-form-item-label > label.ant-form-item-required::before {
  display: none !important;
}

.ant-form-item-label > label.ant-form-item-required::after {
  display: inline-block;
  margin-left: 4px;
  color: #ff4d4f !important;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
  position: relative;
  top: -0.5px;
}

.ant-upload-select-picture-card {
  background: url('../../assets/images/common/testuse.png') !important;
  background-size: cover !important;
  background-position-x: -25px !important ;
}

.ant-form-item-label {
  font-weight: 600;
}

.ant-list-item {
  border-bottom: 0px !important;
}

.anticon-close-circle svg {
  fill: #ff4d4f !important;
  margin-bottom: 6px !important;
}
.anticon-check-circle svg {
  fill: green !important;
  margin-bottom: 8px;
}
.ant-radio-wrapper-checked,
.ant-radio-wrapper-disabled {
  color: black !important;
}

.ant-radio-wrapper[disabled] {
  color: black !important;
}
.ant-radio[disabled] {
  color: black !important;
}

.ant-btn[disabled] span svg {
  fill: rgba(0, 0, 0, 0.4);
}

.ant-radio-wrapper-disabled span,
ant-radio-disabled {
  color: black !important;
}

.ant-upload-list-picture-card .ant-upload-list-item {
  width: 150px !important;
  height: 150px !important;
}

.ant-upload-list-item-actions .anticon-eye {
  display: none !important;
}
.ant-upload-list-item-name-icon-count-1 {
  display: none !important;
}

.ant-upload-list-item-actions .anticon-delete {
  display: none !important;
}
.ant-upload-list-item-actions .anticon-delete svg {
  fill: rgba(0, 0, 0, 0.45) !important;
}

.ant-upload-list-item-file {
  background: url('../../assets/images/common/testuse.png') !important;
  background-size: cover !important;
  background-position-x: -18px !important;
}
.anticon-picture {
  display: none !important;
}
.ant-table-row-level-0 {
  background-color: #fff !important;
}

.ant-layout-sider-children ul li .anticon svg {
  margin-bottom: 6px !important;
}

.ant-picker-cell-inner {
  border-radius: 50% !important;
}
.ant-picker-cell-inner:hover {
  border-radius: 50% !important;
  border: 1px solid black !important;
}
.ant-picker-cell-inner::before {
  border-radius: 50% !important;
}
.ant-btn-primary span {
  color: #fff !important;
}

.ant-input {
  color: black !important;
}

.anticon-plus svg {
  fill: #fff !important;
}

.ant-form-item-explain > div {
  color: #ff4d4f !important;
}
.label-view-detail {
  color: #1890ff !important;
}
.ant-form-item-label > label {
  font-weight: 600;
  color: black;
}

.ant-menu-item:first-child {
  margin-top: 0px;
}
.ant-btn-primary[disabled] span {
  color: black !important;
}
.ant-input[disabled] {
  color: black !important;
  background-color: #fff;
  cursor: not-allowed;
}

.ant-select-disabled.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  color: black !important;
  background-color: #fff;
  cursor: not-allowed;
}

.ant-picker.ant-picker-disabled {
  color: black !important;
  background-color: #fff;
  cursor: not-allowed;
}
.ant-select-disabled {
  color: black !important;
  background-color: #fff;
  cursor: not-allowed;
}
.ant-select-disabled .ant-select-selection-item {
  background-color: #fff;
  color: black !important;
  cursor: not-allowed;
}
input:disabled {
  background-color: #fff;
  color: black !important;
  cursor: not-allowed;
}

.ant-table-tbody .ant-btn {
  border: none;
  box-shadow: none;
  background-color: transparent;
  padding: 0;
}
a {
  color: #1890ff;
}
.ant-menu-submenu-title span {
  display: flex;
  align-items: center;
}

.logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.ant-menu-submenu-title {
  display: flex;
}

a:hover {
  text-decoration: underline;
}

.ant-modal {
  margin-top: 30px;
  padding: 0 15px;
  margin-bottom: 30px;
}

.ant-modal-header {
  text-align: center;
}

.ant-table-wrapper {
  overflow-x: auto;
}

.ant-input[disabled] {
  background-color: #edf2f7;
}

/*.ant-input-number-handler-wrap {
  display: none;
}*/

.ant-table-tbody > tr > td {
  color: #1a202c;
}
.ant-picker {
  width: 100%;
}
.ant-form-item-label > label::after {display: none;}

.upload-images-box {display: flex;flex-wrap: wrap;}
.upload-images-box > div {min-width:400px; margin-bottom: 30px}