.doctor-transaction {
  .form-item {
    position: relative;
    .input {
      width: 50%;
    }
  }
  .label-money {
    position: absolute;
    right: 35%;
    top: 3%;
  }
}
