.doctor-transaction {
  box-sizing: border-box;
  h1 {
    font-weight: 500;
    font-size: 20px;
  }
  .row-search {
    margin-top: 30px;
  }
}
