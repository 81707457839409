.check-before-container {
  padding: 0px 20px 30px 20px;
  width: 100%;
  word-break: break-all;
  .row-11 {
    background-color: #f2f2f2;
    margin-bottom: 20px;
    .not-found-content {
      .no-content {
        padding: 10px 0;
        width: 100%;
        text-align: center;
      }
    }
    .check-before-section {
      display: flex;
      flex-direction: column;
      width: 100%;

      .check-before-section__title {
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 10px;
        font-weight: bold;
        .btn-edit {
          padding-top: 10px;
          margin-right: 10px;
          button {
            border-radius: 5px;
          }
        }
      }
      .check-before-section__content {
        padding: 20px 10px;
        .check-before-section__content_inside {
        }
      }
    }
  }
  .check-before-add-container {
    margin: 0 auto;
    margin-bottom: 30px;
    padding-top: 30px;
    .check-before-action-add {
      display: flex;
      justify-content: space-between;
      .btn-ok {
        button {
          padding: 0 45px;
          border-radius: 5px;
        }
      }
      .btn-cancel {
        padding-top: 5px;
        cursor: pointer;
      }
    }
  }
}

.__title {
  width: 100%;
  font-size: 20px;
  height: 40px;
  font-weight: bold;
}
