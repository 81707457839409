.fee-detail {
  box-sizing: border-box;
  text-align: center;
  h1 {
    font-weight: 500;
    font-size: 20px;
  }
  .fee-detail-value {
    display: flex;
    .label-vnd {
      margin-left: -30px;
    }
  }
}
