.modal-detail-customer {
  .ant-form-item-label {
    text-align: left;
    font-weight: normal;
    label {
      color: rgba(0, 0, 0, 0.65);
    }
  }

  .button-container {
    text-align: center;
  }
}
