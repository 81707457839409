.historyCheck-Contaier {
  .historyCheck-content {
    .historyCheck-content__title {
      width: 100%;
      font-size: 20px;
      height: 40px;
      font-weight: bold;
    }
    .row-1 {
      background-color: #f2f2f2;
      margin: 30px 20px;
      .historyCheck-section {
        display: flex;
        flex-direction: column;
        .historyCheck-section__title {
          height: 35px;
          display: flex;
          align-items: center;
          padding-left: 10px;
        }
        .historyCheck-section__content {
          padding: 20px 10px;
        }
      }
    }
  }
  .historyCheck-viewmore {
    display: flex;
    justify-content: center;
    .historyCheck-viewmore__btn {
      border-radius: 5px;
    }
  }
}

.loading {
  padding: 0px 20px;
}
.historyCheck-section__content_inside {
  margin-bottom: 30px;
  word-break: break-all;
}

.diagnostic-section__title {
  align-items: center;
  font-weight: bold;
}
