$bp-small: 48em; // 768px
$bp-medium: 64em; // 1024px
$bp-large: 85.375em; // 1366px
$bp-xlarge: 120em; // 1920px
$bp-xxlarge: 160em; // 2560px

// Media Queries
$mq-small: '(min-width: #{$bp-small})';
$mq-medium: '(min-width: #{$bp-medium})';
$mq-large: '(min-width: #{$bp-large})';
$mq-xlarge: '(min-width: #{$bp-xlarge})';
$mq-xxlarge: '(min-width: #{$bp-xxlarge})';
$mq-retina: '(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)';
.layout-app {
  height: 100%;
  padding: 24px 24px 0 24px;
  min-height: 360;
  overflow-y: auto;
  margin: 0 -16px;
  .layout-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    .layout-container {
      flex: 1;
    }
    .copy-right {
      text-align: center;
      padding: 16px 0;
    }
  }
}
.menu-side-custom {
  @media only screen and (max-height: 768px) {
    height: 80%;
  }
  @media only screen and (max-width: 85.375em) {
    height: 55%;
  }
}
// Breakpoints
@media only screen and (max-width: 85.375em) {
  .layout-app {
    height: 85%;
    padding: 24px 24px 0 24px;
    overflow-y: auto;
    .layout-content {
      display: flex;
      flex-direction: column;
      height: 80%;
      .layout-container {
        flex: 1;
      }
      .copy-right {
        text-align: center;
        padding: 16px 0;
      }
    }
  }
}
