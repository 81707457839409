.wrapper-item {
  height: 56px;
}
.logo {
  display: flex;
}
.imageEdit {
  display: flex;
  align-items: center;
  margin-top: 20px;
  // .ant-image {
  //   margin-left: 20px;
  // }
  .ant-image-img {
    height: 100px !important;
  }

  // .text-image {
  //   white-space: nowrap;
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  //   width: 60%;
  // }
}
