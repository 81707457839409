.wrapper-item {
  height: 56px;
}
.button-add-container {
  display: flex;
  justify-content: center;
  align-items: center;
  .export-button {
    white-space: normal;
    font-size: 14px;
    line-height: 14px;
    padding: 0;
  }
  .exit-button {
    font-size: 14px;
  }
}
.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-right: 8px;
}
.upload-list-inline .ant-upload-animate-enter {
  animation-name: uploadAnimateInlineIn;
}
.upload-list-inline .ant-upload-animate-leave {
  animation-name: uploadAnimateInlineOut;
}
