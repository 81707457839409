div.modal-confirm {
  padding-top: 30px;
  text-align: center;
  .button-container {
    margin-top: 20px;
    text-align: right;
    button {
      margin: 0 10px;
      max-width: 100px;
      width: 100%;
    }
  }
  p {
    display: flex;
    align-items: center;

    span {
      padding-left: 10px;
    }
  }
}
