@import './../../../../assets/styles/media-device.scss';
.infoUser-Contaier {
  .title-container {
    .row-1 {
      height: 40px;
      .wrap-calender-icon {
        margin-top: 5px;
      }
      .wrap-clock-icon {
        margin-top: 5px;
      }
    }
    .info-basic {
      margin: 20px;
      display: flex;
      .avatar {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-items: center;
        @media screen and (max-width: $md) {
          width: 70px;
          height: 70px;
        }
        .image-default {
          width: 100%;
          max-height: 100%;
          object-fit: cover;
        }
        .avatar-user {
          width: 100%;
          max-height: 100%;
          object-fit: cover;
        }
      }
      .info-detail {
        flex: 1;
        padding-left: 1rem;
        .info-name {
          font-weight: bold;
        }
      }
    }
  }
}
