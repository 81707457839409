.anamnesis-Contaier {
  .anamnesis-content {
    .anamnesis-content__title {
      width: 100%;
      font-size: 20px;
      height: 40px;
      font-weight: bold;
    }
    .row-1 {
      background-color: #f2f2f2;

      margin: 10px 20px 15px 20px;

      .anamnesis-section {
        display: flex;
        flex-direction: column;
        .anamnesis-section__title {
          height: 35px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-left: 10px;
          font-weight: bold;
          .btn-edit {
            padding-top: 10px;
            margin-right: 10px;
            margin-bottom: 2px;
            button {
              border-radius: 5px;
            }
          }
        }
        .no-content {
          padding: 10px 0;
          // border: 1px solid rgba(0,0,0,0.5);
          width: 100%;
          text-align: center;
        }
        .anamnesis-section__content {
          padding: 5px 10px;
          word-break: break-word;
          .anamnesis-section__content_inside {
            .text-have-color-#555555 {
              color: red !important;
            }
          }
        }
      }
    }
  }

  .anamnesis-add-container {
    margin: 30px 20px;

    .anamnesis-action-add {
      display: flex;
      justify-content: space-between;
      .btn-ok {
        button {
          padding: 0 45px;
          border-radius: 5px;
        }
      }
      .btn-cancel {
        cursor: pointer;
      }
    }
  }
}
