.ant-image-preview-img-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-image {
  margin: 0px 15px 0px 0px;
  width: 200px !important;
}

.imageStyle {
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.ant-image-mask-info {
  display: flex;
  align-items: center;
}

.imageWrapper {
  position: relative;
}
