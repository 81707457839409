.drugOrder-action-add {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 30px;
  .btn-ok {
    button {
      padding: 0 45px;
      border-radius: 5px;
    }
  }
  .btn-cancel {
    button {
      padding: 0 45px;
      border-radius: 5px;
    }
  }
}
