.form-cls {
  .ant-form-item-label {
    display: flex;
    align-items: center;
  }
}

.cls-action-add {
  .btn-ok {
    button {
      padding: 0 45px;
      border-radius: 5px;
    }
  }
  .btn-cancel {
    padding-top: 5px;
    cursor: pointer;
  }
}
