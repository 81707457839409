.modal-checkout {
  .ant-modal-body {
    padding: 0 24px 24px 24px !important;
    .confirm-modal-checkout {
      padding: 5px;
    }
  }
  .ant-modal-header {
    border-bottom: none !important;
  }
}
