.modal-container {
  .ant-modal-header {
    .ant-modal-title {
      text-align: left !important;
    }
  }
  .select-box {
    padding-top: 20px;
    .select-box__title {
    }
    .select-box__box {
      position: relative;
      display: flex;
      align-items: center;
      padding-top: 10px;
      .select-box__box-1 {
        padding-right: 20px;
        .form-items {
        }
      }
      .select-box__box-2 {
        display: flex;
        align-items: center;
        position: absolute;
        left: 100px;
        top: 15px;
      }
    }
  }
  .radio-container {
    .radio-container__1 {
      display: flex;
    }
    .radio-container__2 {
      display: flex;
    }
  }
  .button-cancel-add-information-container {
    padding-top: 20px;
    display: flex;
    justify-content: center;
  }
  .result_title {
    margin: 10px 0px;
  }
}
