.reasonCheck-Contaier {
  .reasonCheck-content {
    .reasonCheck-title {
      width: 100%;
      font-size: 20px;
      height: 40px;
      font-weight: bold;
    }
  }
  .row-1 {
    background-color: #f2f2f2;
    margin: 30px 20px;
    .reason-check-section {
      display: flex;
      flex-direction: column;
      .reason-check-section__title {
        height: 35px;
        display: flex;
        align-items: center;
        padding-left: 10px;
        font-weight: bold;
      }
      .reason-check-section__content {
        padding: 20px 10px;
        .reason-check-section__content_inside {
        }
      }
    }
  }
  .row-2 {
    background-color: #f2f2f2;
    margin: 30px 20px;
    .description-section {
      display: flex;
      flex-direction: column;
      .description-section__title {
        height: 35px;
        display: flex;
        align-items: center;
        padding-left: 10px;
        font-weight: bold;
      }
      .description-section__content {
        padding: 20px 10px;
        .description-section__content_inside {
        }
      }
    }
  }

  .row-3 {
    background-color: #f2f2f2;
    margin: 30px 20px;
    .question-section {
      width: 100%;
      display: flex;
      flex-direction: column;
      .question-section__title {
        height: 35px;
        display: flex;
        align-items: center;
        padding-left: 10px;
        font-weight: bold;
      }
      .question-section__content {
        padding: 20px 10px;
        .question-section__content_inside {
        }
      }
    }
  }
}

.row-4 {
  background-color: #f2f2f2;
  margin: 30px 20px;
  padding: 15px 0px 15px 10px;
}

.olderResultTitle {
  padding: 15px 0px 15px 0px;
  font-weight: bold;
}

.question-label {
  padding: 15px 0px 15px 10px;
  font-weight: bold;
}

.no-content {
  padding: 10px 0;
  // border: 1px solid rgba(0,0,0,0.5);
  width: 100%;
  text-align: center;
}
