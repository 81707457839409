.instruction-Contaier {
  .instruction-content {
    .instruction-title {
      width: 100%;
      font-size: 20px;
      height: 40px;
      font-weight: bold;
    }
  }
  .row-1 {
    background-color: #f2f2f2;
    margin: 30px 20px;
    .anamnesis-section__title {
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 10px;
      font-weight: bold;
      .btn-edit {
        padding-top: 10px;
        margin-right: 10px;
        button {
          border-radius: 5px;
        }
      }
    }
    .no-content {
      padding: 10px 0;
      // border: 1px solid rgba(0,0,0,0.5);
      width: 100%;
      text-align: center;
    }
    .instruction-section {
      display: flex;
      flex-direction: column;
      word-break: break-all;
      .instruction-section__title {
        height: 35px;
        display: flex;
        align-items: center;
        padding-left: 10px;
        font-weight: bold;
        justify-content: space-between;
        .btn-edit {
          margin-top: 10px;
          margin-right: 10px;
          border-radius: 5px;
        }
      }
      .instruction-section__timeEdit {
        padding-left: 10px;
      }

      .instruction-section__content {
        padding: 20px 10px;
        .instruction-section__content_inside {
        }
      }
    }
  }
  .instruction-add-container {
    margin: 30px 20px;
    .instruction-action-add {
      display: flex;
      justify-content: space-between;
      .btn-ok {
        button {
          padding: 0 45px;
          border-radius: 5px;
        }
      }
      .btn-cancel {
        padding-top: 5px;
        cursor: pointer;
      }
    }
  }
}
