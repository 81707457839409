.edit-doctor {
  box-sizing: border-box;
  background-color: #fff;
  padding: 20px;

  h1 {
    font-weight: 500;
    font-size: 20px;
  }
  .box_image_2 {
    .ant-upload-select-picture-card {
      width: 10px !important;
      height: 10px !important;
      z-index: 1000 !important;
      top: 45% !important;
      left: 50px !important;
      border: none !important;
      background: rgba(0, 0, 0, 0) !important;
      position: absolute !important;
      padding: 0 !important;
      margin: 0 !important;
    }
    .ant-form-item-explain {
      padding-top: 30px !important;
    }
    .avatar {
      box-sizing: border-box !important;

      .blank_image {
        border: 1px solid rgba($color: #000000, $alpha: 0.1);
        width: 150px;
        height: 150px;
        background: url('../../../assets/images/common/testuse.png') !important;
        background-size: cover !important;
        background-position-x: -18px !important;
      }
    }
  }

  .box_image_1 {
    display: relative;
    .ant-form-item-explain {
      padding-top: 30px !important;
    }
    .ant-upload-select-picture-card {
      width: 10px !important;
      height: 10px !important;
      z-index: 1000 !important;
      top: 45% !important;
      left: 50px !important;
      border: none !important;
      background: rgba(0, 0, 0, 0) !important;
      position: absolute !important;
      padding: 0 !important;
      margin: 0 !important;
    }
    .cover {
      box-sizing: border-box !important;

      .blank_image {
        border: 1px solid rgba($color: #000000, $alpha: 0.1);
        width: 150px;
        height: 150px;
        background: url('../../../assets/images/common/testuse.png') !important;
        background-size: cover !important;
        background-position-x: -18px !important;
      }
    }
  }

  .form-item {
    padding: 0;

    .addPlace {
      display: flex;
      align-items: center;
    }
  }
  .wrap-btn-add {
    height: 0;
    margin: 0;
    padding: 0;
  }
  .exp {
    position: relative;
    .ant-form-item-explain {
      padding-left: 5%;
    }
    .test {
      width: 80%;
      box-sizing: border-box;
      .input-edit-year {
        width: 25%;
        margin-left: 5%;
      }
    }

    .title-year {
      position: absolute;
      display: flex;
      left: 34%;
      padding-top: 5px;
    }
  }
  // .input-type {
  //   width: 20%;
  // }
  .fee {
    position: relative;
    .ant-form-item-explain {
      padding-left: 5%;
    }
    .fee-test {
      width: 80%;
      box-sizing: border-box;
      .input-type {
        margin-left: 5%;
        width: 25%;
      }
    }
    .title-money {
      position: absolute;
      display: flex;
      left: 34%;
      padding-top: 5px;
    }
  }

  .button-container {
    padding-top: 20px;
    display: flex;
    justify-content: center;
  }
  .specialist.edit-doctor .ant-form-item-control-input {
    width: 25% !important;
    // margin-left: 60px;
  }
}

.specialist {
  .ant-form-item-label {
    display: flex;
  }
  .ant-form-item-control-input {
    width: 25%;
  }
}
.specialist-checkbox {
  display: flex !important;
}
.ant-input-number .ant-input-number-in-form-item .ant-input-number-status-error .input-type {
  width: 100% !important;
}