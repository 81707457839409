.check-Container {
  .check-content__title {
    width: 100%;
    font-size: 20px;
    height: 40px;
    font-weight: bold;
  }
  .row-1 {
    background-color: #f2f2f2;
    margin: 30px 20px;
    .check-section {
      display: flex;
      flex-direction: column;
      width: 100%;

      .check-section__title {
        height: 35px;
        display: flex;
        align-items: center;
        padding-left: 10px;
        font-weight: bold;
      }
      .info-check {
        .infor-container {
          padding: 20px 15px;

          margin: 0 auto;
          .fulse-container__wrap {
            background-color: #fff;
            padding: 5px 10px 20px 7px;
            .div-1 {
              font-weight: bold;
              display: flex;
              justify-content: space-between;
              .title {
                padding: 5px 0 0 10px;
              }
              .btn-edit {
                // padding: 5px 10px 0 0;
              }
              .btn-update {
                border: 1px solid #40a9ff;
                span {
                  color: #40a9ff;
                }
              }
              .btn-cancel {
                margin-left: 10px;
                border: 1px solid red;
                span {
                  color: red;
                }
              }
            }
            .div-2 {
              padding: 0 10px;
              font-weight: Bold;
              font-size: 15px;
              display: flex;
              align-items: center;
              position: relative;

              .text-index-body {
                margin-top: 15px;
                font-size: 20px;
              }

              .div-2-form {
                width: 100%;
                padding-right: 10px;
                margin-top: 10px;
              }
              .div-2-span {
                position: absolute;
                top: 13px;
                right: 10px;
              }
            }
          }
        }
      }
    }
  }
}

.text-unit {
}
